import React from "react"

import {
  contactmethods,
  contactmethods_section,
  contactmethods_section_title,
  contactmethods_section_link,
} from "./ContactMethods.module.css"

const ContactMethods = () => {
  return (
    <div className={contactmethods}>
      <div className={contactmethods_section}>
        <h2 className={contactmethods_section_title}>Email</h2>
        <a
          href="mailto:info@medeamp.com"
          className={contactmethods_section_link}
        >
          info@medeamp.com
        </a>
      </div>

      <div className={contactmethods_section}>
        <h2 className={contactmethods_section_title}>Phone</h2>
        <div>(925) 425-9282</div>
      </div>

      <div className={contactmethods_section}>
        <h2 className={contactmethods_section_title}>
          Northern California Headquarters
        </h2>
        <div>Medea Inc</div>
        <div>351 Saint Mary Street</div>
        <div>Pleasanton, CA 94566</div>
      </div>

      {/* <div className={contactmethods_section}>
        <h2 className={contactmethods_section_title}>
          Southern California Headquarters
        </h2>
        <div>Medea Inc</div>
        <div>2523 W. Coast Highway</div>
        <div>Newport Beach, CA 92663</div>
      </div> */}
    </div>
  )
}

export default ContactMethods
