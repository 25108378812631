import * as React from "react"
import ContactMethods from "../components/ContactMethods"
import ContactForm from "../components/ContactForm"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = ({ location }) => {

  return (
    <Layout location={location}>
      <Seo title="Contact Us" />
      <div className="section-wrapper page-main">
        <h1 className="page-main-title">Contact Us</h1>
        <ContactMethods />
        <ContactForm />
      </div>
    </Layout>
  )
}

export default Contact