import React, { useRef } from "react"
import emailjs from "emailjs-com"

import {
  form_main,
  form_title,
  form_description,
  form_inputs,
  form__input,
  form__input_body,
  form__label,
  form__button,
  form__button_sent,
} from "./ContactForm.module.css"

const ContactForm = () => {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()
    const btn = document.getElementById("button")
    const input1 = document.getElementById("name")
    const input2 = document.getElementById("email")
    const input3 = document.getElementById("subject")
    const input4 = document.getElementById("body")

    btn.value = "Submitting..."

    emailjs
      .sendForm(
        "service_9c326dq",
        "template_c0sob9j",
        form.current,
        "user_KKdMFvrpfNAAfKAMLtEc1"
      )
      .then(
        result => {
          input1.value = ""
          input2.value = ""
          input3.value = ""
          input4.value = ""
          btn.value = "Submitted!"
          btn.className = form__button_sent
          btn.disabled = true
        },
        error => {
          alert(
            "There was an error sending your message. Please try again later."
          )
        }
      )
  }

  return (
    <form className={form_main} ref={form} onSubmit={sendEmail}>
      <h2 className={form_title}>Reach out directly</h2>
      <div className={form_description}>
        We will email you back as soon as we can.
      </div>

      <div className={form_inputs}>
        <label className={form__label} htmlFor="name">
          Full Name
        </label>
        <input className={form__input} type="text" name="name" id="name" />

        <label className={form__label} htmlFor="email">
          Email
        </label>
        <input className={form__input} type="email" name="email" id="email" />

        <label className={form__label} htmlFor="subject">
          Subject
        </label>
        <input
          className={form__input}
          type="text"
          name="subject"
          id="subject"
        />

        <label className={form__label} htmlFor="body">
          Body
        </label>
        <textarea
          className={form__input_body}
          type="text"
          name="body"
          id="body"
          rows="12"
        />

        <input
          className={form__button}
          type="submit"
          value="Submit"
          id="button"
        />
      </div>
    </form>
  )
}

export default ContactForm
